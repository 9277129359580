<template>
  <b-modal
    title="New answer"
    :visible="visible"
    no-close-on-backdrop
    @ok="onSubmit"
    :ok-title="$t('form.actions.save')"
    @change="$emit('change', false)"
  >
  <div>
      <h5 v-if="item.name != null" class="font-weight-extrabold mb-2" >{{ item.name[locale] || Object.values(item.name)[0]  }}</h5>
    <label for="answer" class="font-weight-bold"> {{$t('backoffice.faqs.answer-write')}}:</label>
    <quill-editor id="answer"  v-model="answer" />
  </div>
    
  </b-modal>
</template>

<script>
import {
  BModal,BForm, BFormGroup, BButton, BFormInvalidFeedback, BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { quillEditor } from 'vue-quill-editor';
import { required } from '@validations';

export default {
  name: 'AnswerModal',
  components: {
    BForm,
    BModal,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    item: {
      type: Object,
      required
    },
  },
  data() {
    return {
      answer: '',
    };
  },
  computed:{
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  methods: {
    async onSubmit() {
      try {
        const response = await this.$store.dispatch('createItem', {
        item: {
            itemType: 'createAnswer',
            requestConfig:{
                questionKey: this.item.key,
                description: this.answer
            },
          },
          noSet: true
        });
        response.data.questionKey = this.item.key;
        this.$emit("update", response.data)
        this.notifySuccess(this.$t('backoffice.faqs.answer-alert'));
        this.$emit('close-modal', false);
        this.answer = '';
      } catch {
        this.notifyError(this.$t('share.notify-error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;

  }
}
</style>
