<template>
  <div>
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <template v-else>
      <div
        v-if="items && items.length > 0"
        id="scrollContainer"
        class="d-flex flex-column pr-25"
      >
        <!-- Items loop -->
        <div
          v-for="(item, index) in items"
          :key="index"
          class="x-scroll-element mx-0"
        >
          <question-card
            :is-small-widget="isSmallWidget"
            :item="item"
            :is-widget="isWidget"
            @fetchData="fetchData"
          />
        </div>
        <b-spinner
          v-if="isLoadingNextPage"
          type="grow"
          small
          class="mx-auto my-2 d-block"
        />
        <b-button
          v-else-if="items && items.length < totalItems"
          class="d-block text-center m-auto"
          variant="link"
          :disabled="isLoadingNextPage"
          @click="handleLoadOfNewItems"
        >
          {{ $t("action.load-more") }}
        </b-button>
      </div>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="questionsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: $t("questions.title") }) }}
          </p>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import OrderBy from '@/@core/constants/OrderBy';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import QuestionsPlaceholder from '@/assets/images/placeholders/light/Preguntas.svg';
import QuestionCard from '@/views/apps/questions/components/QuestionCard.vue';

export default {
  name: 'QuestionsList',
  components: {
    QuestionCard,
  },
  mixins: [WidgetLayoutMixin],
  props: {
    isWidget: Boolean,
    isSmallWidget: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      projects: [],
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      questionName: '',
      answer: '',
      actualItem: {},
      questionKey: '',
      answerKey: '',
      editing: false,
      totalItems: 0,
      editingAnswer: false,
      editForm: {
        name: '',
        answer: '',
      },
    };
  },
  computed: {
    itemsData() {
      const items = this.$store.getters.questions?.unpaginated;
      return items;
    },
    items() {
      return [...this.itemsData].sort((a, b) => b.totalLikes - a.totalLikes);
    },
    total() {
      this.totalItems = this.itemsData.length;
      return this.itemsData.length;
    },
    questionsPlaceholder() {
      return QuestionsPlaceholder;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    if (!this.itemsData || this.$store.getters.questions?.unpaginated.length === 0) await this.fetchData();
    this.isLoading = false;
    this.$emit('isLoading', this.isLoading);
  },
  methods: {
    async fetchData(forceAPICall = true) {
      await this.$store.dispatch('getItems', {
        itemType: 'questions',
        page: this.lastLoadedPage,
        perPage: 100,
        forceAPICall,
        orderByCreationTime: OrderBy.DESC,
        requestConfig: { answerCount: 5 },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length <= total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('questions.title'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
