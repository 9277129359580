<template>
  <b-card
    v-if="canViewQuestions(item)"
    class="card-question pb-2"
    no-body
  >
    <!-- Small Addon -->
    <b-row v-if="isSmallWidget">
      <!-- Arrows with likes column -->
      <b-col
        cols="2"
        class="text-center mt-1"
        style="min-width:30px"
      >
        <div class="text-secondary">
          <feather-icon
            :class="{ 'text-primary': item.likedByMember }"
            class="w-100 icon-clickable"
            icon="ArrowUpIcon"
            size="20"
            @click="toggleLike(item.key)"
          />
          <h6
            class="w-100"
            style="
                      font-weight: 600;
                      margin-top: 0.3rem;
                      margin-bottom: 0.3rem;
                    "
          >
            {{ item.totalLikes }}
          </h6>
          <feather-icon
            class="w-100 icon-clickable"
            icon="ArrowDownIcon"
            size="20"
            @click="!item.likedByMember || toggleLike(item.key)"
          />
        </div>
      </b-col>
      <!-- Main content -->
      <b-col class="pl-0" cols="10">
        <h5
          v-if="
            (canViewQuestions(item) && questionKey !== item.key) ||
              (canViewQuestions(item) && !editing)
          "
          class="font-weight-extrabold mt-1 mb-50"
        >
          {{ translate(item.name) }}
        </h5>
        <p
          v-else
          class=" font-weight-extrabold mt-1 mb-50"
        >
          {{ $t("questions-addon.item-no-permission") }}
        </p>
        <b-form-input
          v-if="editing && questionKey === item.key"
          v-model="editForm.name"
          :value="translate(item.name) "
          class="mt-1 mb-1 form-label"
        />
        <p
          v-if="item.description"
          class="mb-1"
          v-html="translate(item.description)"
        />
        <div>
          <div class="text-muted font-weight-normal mt-1">
            <span v-if="item.createdByMember">
              {{ $t("common.created-by") }} <b-link :to="getMemberLocation(item.createdByMember)">
                {{ item.createdByMember.name }}
                {{ item.createdByMember.surname }}
              </b-link>
            </span>
          </div>
          <div class="text-muted small mt-25 font-weight-normal ">
            <span class="mr-2 d-flex align-items-center">
              <feather-icon
                icon="ClockIcon"
                size="16"
                style="margin-right: 5px"
              />
              {{ timestampToTime(item.createdAt) }}
            </span>
          </div>
        </div>
        <!-- Answers -->
        <div v-if="canViewAnswers(item) && item.answers.length > 0">
          <div class="d-flex justify-content-between align-items-center mt-2 mb-50 ">
            <h6 class="mb-0">
              {{ $t("backoffice.faqs.answers") }}:
            </h6>
            <div v-if="showAnswers" class="d-flex pointer align-items-center" @click="toggleNavigation">
              <p class="small mb-0 mr-25">
                {{ $t("articles.detail.hide") }}
              </p>
              <feather-icon
                class="pointer"
                icon="ChevronDownIcon"
              />
            </div>
            <div v-else class="d-flex pointer align-items-center" @click="toggleNavigation">
              <p class="small mb-0 mr-25">
                {{ $t("articles.detail.show") }}
              </p>
              <feather-icon
                class="pointer"
                icon="ChevronUpIcon"
              />
            </div>
          </div>
          <div
            v-for="(answer,index) in item.answers"
            v-show="showAnswers"
            :key="index"
          >
            <feather-icon
              v-if="
                canEditOwn &&
                  editing &&
                  questionKey === item.key &&
                  !editingAnswer
              "
              icon="Edit2Icon"
              size="20"
              class="text-primary edit-icon float-right ml-1"
              role="button"
              @click="chooseAnswer(answer)"
            />
            <div
              v-if="editingAnswer && answerKey === answer.key"
              class="float-right d-flex mt-n2"
            >
              <feather-icon
                icon="XCircleIcon"
                size="20"
                class="text-primary edit-icon mr-1"
                role="button"
                @click="closeEdit('answer')"
              />
              <feather-icon
                icon="SaveIcon"
                size="20"
                class="text-primary edit-icon"
                role="button"
                @click="editAnswer(answer, item)"
              />
            </div>

            <div
              v-if="answerKey !== answer.key"
            >
              <p class="d-flex  mb-0">
                <span class="h5 mt-25">{{ index+1 }}.</span> <span class="ml-50" v-html="answer.description" />
              </p>
              <div class="mt-n50 mb-2">
                <div class="text-muted font-small-2 pl-2">
                  <span v-if="answer.createdByUser">
                    {{ $t("common.created-by") }} <b-link class="text-underline text-muted" :to="getMemberLocation(answer.createdByMember)">
                      {{ answer.createdByUser.name }}
                      {{ answer.createdByUser.surname }}
                    </b-link>
                  </span>
                </div>
                <div class="text-muted font-small-2 pl-2">
                  <span class="d-flex align-items-center">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                      style="margin-right: 5px"
                    />
                    {{ timestampToTime(answer.createdAt) }}
                  </span>
                </div>
              </div>
            </div>

            <quill-editor
              v-else-if="editingAnswer && answerKey === answer.key"
              v-model="editForm.answer"
              class="mb-3 mt-3"
            />
          </div>
        </div>
        <div class="mt-1">
          <b-button
            v-if="
              (canCreateAnswer && questionKey !== item.key) ||
                (canCreateAnswer && !editing)
            "
            variant="primary"
            size="sm"
            @click="openAnswerModal(item)"
          >
            {{ $t("backoffice.faqs.answer") }}
          </b-button>

          <feather-icon
            v-if="
              (!isWidget &&
                canViewQuestions(item) &&
                !editing &&
                canEdit) ||
                (!isWidget &&
                  canViewQuestions(item) &&
                  canEdit &&
                  questionKey !== item.key)
            "
            icon="Edit2Icon"
            size="20"
            style="cursor: pointer"
            class="text-primary edit-icon ml-1 right-icon"
            role="button"
            @click="chooseQuestion(item)"
          />
          <feather-icon
            v-if="
              (!isWidget &&
                canViewQuestions(item) &&
                !editing &&
                canDelete) ||
                (!isWidget &&
                  canViewQuestions(item) &&
                  canEdit &&
                  questionKey !== item.key)
            "
            icon="Trash2Icon"
            size="20"
            style="cursor: pointer"
            class="text-primary edit-icon ml-1 right-icon"
            role="button"
            @click="
              (isUnshareModalVisible = true) &&
                (questionKey = item.key)
            "
          />
          <div class="icons d-flex align-items-center right-icon">
            <b-button
              v-if="editing && questionKey === item.key"
              size="sm"
              variant="primary"
              style="cursor: pointer"
              @click="editQuestion(item)"
            >
              {{ $t("form.actions.save") }}
            </b-button>
            <feather-icon
              v-if="editing && questionKey === item.key"
              icon="XCircleIcon"
              size="20"
              style="cursor: pointer"
              class="text-primary edit-icon ml-1"
              role="button"
              @click="closeEdit('question')"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Big addon -->
    <b-row v-else class="px-50">
      <!-- Arrows with likes column -->
      <b-col
        cols="1"
        class="text-center mt-1"
        style="min-width:30px"
      >
        <div class="text-secondary">
          <feather-icon
            :class="{ 'text-primary': item.likedByMember }"
            class="w-100 icon-clickable"
            icon="ArrowUpIcon"
            size="20"
            @click="toggleLike(item.key)"
          />
          <h6
            class="w-100"
            style="
                      font-weight: 600;
                      margin-top: 0.3rem;
                      margin-bottom: 0.3rem;
                    "
          >
            {{ item.totalLikes }}
          </h6>
          <feather-icon
            class="w-100 icon-clickable"
            icon="ArrowDownIcon"
            size="20"
            @click="!item.likedByMember || toggleLike(item.key)"
          />
        </div>
      </b-col>
      <!-- Main content -->
      <b-col class="pl-0 d-flex" cols="11" :class="isWidget?'':'pr-2'">
        <b-row class="w-100">
          <b-col cols="7" md="8" lg="9">
            <h5
              v-if="
                (canViewQuestions(item) && questionKey !== item.key) ||
                  (canViewQuestions(item) && !editing)
              "
              class="font-weight-extrabold mt-1 mb-50"
            >
              {{ translate(item.name) }}
            </h5>
            <p
              v-else
              class="font-weight-extrabold mt-1 mb-50"
            >
              {{ $t("questions-addon.item-no-permission") }}
            </p>
            <b-form-input
              v-if="editing && questionKey === item.key"
              v-model="editForm.name"
              :value="translate(item.name) "
              class="mt-1 mb-1 form-label"
            />
            <p
              v-if="item.description"
              class="mb-1"
              v-html="translate(item.description)"
            />
          </b-col>
          <b-col
            cols="5"
            md="4"
            lg="3"
            class="justify-content-end d-flex align-items-center "
          >
            <div class="float-right mt-1 icon-container p-0">
              <b-button
                v-if="
                  (canCreateAnswer && questionKey !== item.key) ||
                    (canCreateAnswer && !editing)
                "
                variant="primary"
                size="sm"
                @click="openAnswerModal(item)"
              >
                {{ $t("backoffice.faqs.answer") }}
              </b-button>

              <feather-icon
                v-if="
                  (!isWidget &&
                    canViewQuestions(item) &&
                    !editing &&
                    canEdit) ||
                    (!isWidget &&
                      canViewQuestions(item) &&
                      canEdit &&
                      questionKey !== item.key)
                "
                icon="Edit2Icon"
                size="20"
                style="cursor: pointer"
                class="text-primary edit-icon ml-1 right-icon"
                role="button"
                @click="chooseQuestion(item)"
              />
              <feather-icon
                v-if="
                  (!isWidget &&
                    canViewQuestions(item) &&
                    !editing &&
                    canDelete) ||
                    (!isWidget &&
                      canViewQuestions(item) &&
                      canEdit &&
                      questionKey !== item.key)
                "
                icon="Trash2Icon"
                size="20"
                style="cursor: pointer"
                class="text-primary edit-icon ml-1 right-icon"
                role="button"
                @click="
                  (isUnshareModalVisible = true) &&
                    (questionKey = item.key)
                "
              />
              <div class="icons d-flex align-items-center right-icon">
                <b-button
                  v-if="editing && questionKey === item.key"
                  size="sm"
                  variant="primary"
                  style="cursor: pointer"
                  @click="editQuestion(item)"
                >
                  {{ $t("form.actions.save") }}
                </b-button>
                <feather-icon
                  v-if="editing && questionKey === item.key"
                  icon="XCircleIcon"
                  size="20"
                  style="cursor: pointer"
                  class="text-primary edit-icon ml-1"
                  role="button"
                  @click="closeEdit('question')"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-between mt-1 ">
            <div class="text-muted font-weight-normal ">
              <span v-if="item.createdByUser">
                {{ $t("common.created-by") }} <b-link :to="getMemberLocation(item.createdByMember)">
                  {{ item.createdByUser.name }}
                  {{ item.createdByUser.surname }}
                </b-link>
              </span>
            </div>
            <div class="text-muted font-weight-normal">
              <span class="d-flex align-items-center">
                <feather-icon
                  icon="ClockIcon"
                  size="16"
                  style="margin-right: 5px"
                />
                {{ timestampToTime(item.createdAt) }}
              </span>
            </div>
          </b-col>
          <b-col cols="12">
            <!-- Answers -->
            <div v-if="canViewAnswers(item) && item.answers.length > 0">
              <div class="d-flex justify-content-between align-items-center mt-2 mb-1 ">
                <h6 class="mb-0">
                  {{ item.answers.length }} {{ $t("backoffice.faqs.answers") }}:
                </h6>
                <div v-if="isWidget">
                  <div v-if="showAnswers" class="d-flex pointer align-items-center" @click="toggleNavigation">
                    <p class="small mb-0 mr-25">
                      {{ $t("articles.detail.hide") }}
                    </p>
                    <feather-icon
                      class="pointer"
                      icon="ChevronDownIcon"
                    />
                  </div>
                  <div v-else class="d-flex pointer align-items-center" @click="toggleNavigation">
                    <p class="small mb-0 mr-25">
                      {{ $t("articles.detail.show") }}
                    </p>
                    <feather-icon
                      class="pointer"
                      icon="ChevronUpIcon"
                    />
                  </div>
                </div>
              </div>
              <div
                v-for="(answer,index) in item.answers"
                v-show="showAnswers"
                :key="index"
              >
                <feather-icon
                  v-if="
                    canEditOwn &&
                      editing &&
                      questionKey === item.key &&
                      !editingAnswer
                  "
                  icon="Edit2Icon"
                  size="20"
                  class="text-primary edit-icon float-right ml-1"
                  role="button"
                  @click="chooseAnswer(answer)"
                />
                <div
                  v-if="editingAnswer && answerKey === answer.key"
                  class="float-right d-flex flex-column"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="20"
                    class="text-primary edit-icon"
                    role="button"
                    @click="closeEdit('answer')"
                  />
                  <feather-icon
                    icon="SaveIcon"
                    size="20"
                    class="text-primary edit-icon mt-1"
                    role="button"
                    @click="editAnswer(answer, item)"
                  />
                </div>

                <div
                  v-if="answerKey !== answer.key"
                >
                  <p class="d-flex mb-0" :class="isWidget?'':'ml-2'">
                    <span class="h5 mt-25">{{ index+1 }}.</span> <span class="ml-50 mb-0" v-html="answer.description" />
                  </p>
                  <div class="d-flex justify-content-between align-items-center mt-n50 mb-2">
                    <div class="text-muted font-small-2 pl-3">
                      <span v-if="answer.createdByUser">
                        {{ $t("common.created-by") }} <b-link class="text-underline text-muted" :to="getMemberLocation(answer.createdByMember)">
                          {{ answer.createdByUser.name }}
                          {{ answer.createdByUser.surname }}
                        </b-link>
                      </span>
                    </div>
                    <div class="text-muted font-small-2">
                      <span class="d-flex align-items-center">
                        <feather-icon
                          icon="ClockIcon"
                          size="14"
                          style="margin-right: 5px"
                        />
                        {{ timestampToTime(answer.createdAt) }}
                      </span>
                    </div>
                  </div>
                </div>

                <quill-editor
                  v-else-if="editingAnswer && answerKey === answer.key"
                  v-model="editForm.answer"
                  class="mb-3 mx-3"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <answer-modal
      v-model="isAnswerModalVisible"
      :item="actualItem"
      @update="fetchData(true)"
    />
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="question"
      @remove-item="removeItem()"
      @modal-invisible="isUnshareModalVisible = false"
    />
  </b-card>
</template>

<script>
import { checkPermissions } from '@/@core/utils/roles-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import AnswerModal from '@/@core/components/modal/AnswerModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';

export default {
  name: 'QuestionCard',
  components: {
    AnswerModal, quillEditor, UnshareItemModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isWidget: Boolean,
    isSmallWidget: { type: Boolean, default: false },

  },
  data() {
    return {
      actualItem: {},
      isAnswerModalVisible: false,
      editing: false,
      editingAnswer: false,
      editForm: {
        name: '',
        answer: '',
      },
      questionKey: '',
      answerKey: '',
      answer: '',
      showAnswers: true,
      isUnshareModalVisible: false,
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    canCreate() {
      return (
        this.isStaff
        || checkPermissions(
          'create',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canCreateAnswer() {
      return (
        this.isStaff
        || checkPermissions(
          'create',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
          'answer',
        )
      );
    },
    canDelete() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'delete',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },

    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'update',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canEditAnswer(item) {
      return (
        this.loggedUser.key === item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'update',
          'question',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
          'answer',
        )
      );
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    if (this.isWidget || this.isSmallWidget) this.showAnswers = false;
  },

  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    toggleNavigation() {
      this.showAnswers = !this.showAnswers;
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: 'member-details',
        params: {
          username: user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    canViewQuestions(item) {
      if (
        item?.createdByUser?.key === this.loggedUser.key
        || this.isStaff
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
        )
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
        )
      ) {
        return true;
      }
      return false;
    },
    canViewAnswers(item) {
      if (
        item?.createdByUser?.key === this.loggedUser.key
        || this.isStaff
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
          'answer',
        )
        || checkPermissions(
          'view',
          'question',
          this.loggedMemberRoles,
          this.collective,
          'answer',
        )
      ) {
        return true;
      }
      return false;
    },
    canEditOwn(question) {
      return this.loggedUser.key === question.createdByUser.key;
    },
    async editQuestion(question) {
      try {
        const editedName = {};
        const locale = this.$store.state.locale.currentLocale;
        editedName[locale] = this.editForm.name;
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'question',
            morphType: 'questions',

            requestConfig: {
              key: question.key,
              name: editedName,
            },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('backoffice.faqs.messages.edit', {
              item: 'Question',
            }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        await this.fetchData();
        this.editForm.name = '';
        this.questionKey = '';
        this.editing = !this.editing;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async editAnswer(answer, question) {
      try {
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'answer',
            morphType: 'question',
            requestConfig: {
              key: answer.key,
              description: this.editForm.answer,
              questionKey: question.key,
            },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('backoffice.faqs.messages.edit', {
              item: 'Question',
            }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.fetchData();
        this.editForm.answer = '';
        this.answerKey = '';
        this.editingAnswer = !this.editingAnswer;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    fetchData() {
      this.$emit('fetchData');
    },
    chooseQuestion(question) {
      this.editForm.name = question.name[this.locale] || Object.values(question.name)[0];
      this.editing = true;
      this.questionKey = question.key;
    },
    chooseAnswer(answer) {
      this.editingAnswer = true;
      this.answerKey = answer.key;
      this.editForm.answer = answer.description;
    },
    closeEdit(value) {
      if (value === 'question') {
        this.questionKey = '';
        this.editing = false;
        this.editForm.name = '';
        this.answerKey = '';
        this.editingAnswer = false;
      } else {
        this.answerKey = '';
        this.editingAnswer = false;
      }
    },
    openAnswerModal(item) {
      this.actualItem = item;
      this.isAnswerModalVisible = !this.isAnswerModalVisible;
    },
    async creatAnswer() {
      await this.$store.dispatch('createItem', {
        item: {
          itemType: 'answers',
          requestConfig,
        },
      });
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    async toggleLike(questionKey) {
      // TODO: Check with BE itemTypes and morphTypes
      await this.$store.dispatch('toggleLike', {
        itemType: 'questions',
        morphType: 'questions',
        key: questionKey,
      });
      this.item.totalLikes = this.item.likedByMember ? ++this.item.totalLikes : --this.item.totalLikes;
    },
    async removeItem() {
      try {
        await this.$store.dispatch('deleteQuestion', {
          item: {
            itemType: 'question',
            requestConfig: {
              key: this.questionKey,
            },
          },
        });
        this.totalItems -= 1;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('unshare.toast-messages.success', { item: 'Forum' }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>
